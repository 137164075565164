<template>
  <div class="List">
    <van-nav-bar title="即将到期" fixed placeholder :z-index="10" left-arrow @click-left="onClickLeft">
      <template #right>
        <span @click="onSerachClick">查询</span>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list-box"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <van-checkbox
            v-if="userBaseInfo.resOperatorDTO.isAdmin"
            class="checkbox"
            v-model="item.checked"
            @change="onItemChange"
            shape="round"
          />
          <CaseItem
            @click.native="item.checked = !item.checked"
            :item="item"
            isExpire
            :isAdmin="userBaseInfo.resOperatorDTO.isAdmin"
            :userInfo="userBaseInfo.resOperatorDTO"
            class="item"
            @actionClick="onActionClick"
          ></CaseItem>
        </div>
      </van-list>
    </van-pull-refresh>
    <SubmitBar
      v-if="userBaseInfo.resOperatorDTO.isAdmin"
      :checked="checkedAll"
      title="续期申请"
      @change="onChange"
      @submit="onSubmit"
    ></SubmitBar>
    <PopupForm
      v-model="showPopup"
      :actionType="actionType"
      :actionItem="actionItem"
      @confirmPopup="confirmPopup"
    >
    </PopupForm>
  </div>
</template>

<script>
import { Search } from "vant";
import { Icon } from "vant";
import { Tabbar, TabbarItem } from "vant";
import popupFormMixin from "@/mixins/popupFormMixin";
import Vue from "vue";
Vue.use(Search, Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
import Api from "@/api/caseHandle";

import { Dialog, Toast } from "vant";
export default {
  mixins: [popupFormMixin],
  props: {},
  components: { SubmitBar: () => import("./components/SubmitBar") },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      checkedAll: false,
      refreshing: false,

      filter: {
        statusEq: "",
        keyLike: "",
        caseType: "overdue",
      },
      pageNo: 1,
      pageSize: 10,
    };
  },
  methods: {
    onChange(v) {
      this.list.forEach((element) => {
        element.checked = v;
      });
      this.checkedAll = v;
    },
    onItemChange() {
      let checked = this.list.filter((item) => item.checked);
      if (checked.length <= 0) {
        this.checkedAll = false;
      }
      if (checked.length == this.list.length) {
        this.checkedAll = true;
      }
    },
    onSubmit() {
      let checkedHomeNos = this.list
        .filter((item) => item.checked)
        .map((item) => item.homeNo);
      if (checkedHomeNos.length <= 0) {
        return Toast.fail("请选择要处理的数据");
      }
      this.$router.push({
        name: "DelayRequest",
        query: {
          homeNo: checkedHomeNos.join(","),
          isExpire: true,
        },
      });
    },
    handelTab() {
      this.onRefresh();
    },
    onSearch() {
      this.pageNo = 1;
      this.list = [];
      this.onLoad();
    },
    onSerachClick() {
      this.$router.push({
        name: "search",
      });
    },
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        const { pageNo, pageSize, filter } = this;
        // 去除空项
        for (const key in filter) {
          if (filter[key] === "" || filter[key] === null) {
            delete filter[key];
          }
        }
        var res = await Api.findPageISO({
          pageNo,
          pageSize,
          ...filter,
        });
        const { data } = res;
        const { list } = data;
        list.forEach((item) => {
          item.checked = false;
        });
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = [...this.list, ...list];
        }
        this.finished = !data.isNextPage;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.pageNo = 1;
      this.onLoad();
    },

    // 反馈
    goFeedBack(item) {
      this.$router.push({
        name: "Settle",
        params: {
          homeNo: item.homeNo,
        },
      });
    },

    // 接单
    async receiveClick(item) {
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认接单",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.receive({
          homeNo: item.homeNo,
        });

        Toast.success(res.message);
        this.onRefresh();
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  mounted() {
    this.fetchEnums("getHomeStatusEnum");
  },
};
</script>

<style lang="less" scoped>
.List {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.van-tabs {
  /deep/ .van-tabs__line {
    background: #3c86ff !important;
    height: 2px !important;
    width: 4em !important;
  }
}

.list-box {
  padding: 10px 12px;
  padding-bottom: 70px;
  .list-item {
    position: relative;
    .checkbox {
      position: absolute;
      right: 17px;
      top: 18px;
      z-index: 3;
      /deep/ .van-checkbox__icon .van-icon {
        border-width: 2px;
      }
    }
  }
  .item {
    margin-bottom: 15px;
  }
}
</style>
